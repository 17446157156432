
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIBasicUsage from "@/views/resources/documentation/element-ui/data/pagination/BasicUsage.vue";
import EUINumberOfPagers from "@/views/resources/documentation/element-ui/data/pagination/NumberOfPagers.vue";
import EUIButtonsWithBackgroudColor from "@/views/resources/documentation/element-ui/data/pagination/ButtonsWithBackgroudColor.vue";
import EUISmallPagination from "@/views/resources/documentation/element-ui/data/pagination/SmallPagination.vue";
import EUIMoreElements from "@/views/resources/documentation/element-ui/data/pagination/MoreElements.vue";
import EUIHidePaginWhenThereIsOnePg from "@/views/resources/documentation/element-ui/data/pagination/HidePaginWhenThereIsOnePg.vue";

export default defineComponent({
  name: "pagination",
  components: {
    EUIBasicUsage,
    EUINumberOfPagers,
    EUIButtonsWithBackgroudColor,
    EUISmallPagination,
    EUIMoreElements,
    EUIHidePaginWhenThereIsOnePg
  },
  setup() {
    setCurrentPageTitle("Pagination");
  }
});
